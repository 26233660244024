import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./CartSidebar.css";
import PhoneOtpModal from "./PhoneOtpModal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { routeurl, weburl } from "../helpers/ApiUrls";
import { Link } from "react-router-dom";
import { useThemeContext } from "../helpers/ThemeContext";
const CartSidebar = ({
    onUpdateCart,
    isOpen,
    onClose,
    onProceedToCheckout,
}) => {
    const {
        handleUpdateCart,
        handleQuantityChange,
        cartItems,
        handleDeleteItem,
    } = useThemeContext();

    const [isMinimized, setIsMinimized] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isPhoneOtpModalVisible, setIsPhoneOtpModalVisible] = useState(false);
    const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
    const [checkoutData, setCheckoutData] = useState({
        addressType: "",
        flatHouseNo: "",
        floor: "",
        landmark: "",
        name: "",
        phone: "",
        payLater: false,
        selectedAddress: "",
    });
    // Retrieve user details from localStorage
    const userId = localStorage.getItem("userId");
    const userPhone = localStorage.getItem("phoneNumber"); // Assuming you have saved phone number here
    const tokenSto = localStorage.getItem("authToken");
    //const savedCheckoutData = localStorage.getItem("checkoutData");
    //console.log("product", savedCheckoutData);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isOpen) {
            setIsMinimized(true);
        }
    }, [isOpen]);

    const [Userdata, setUserdata] = useState([]);

    const getuserdata = async (userId) => {
        try {
            // Send the data to the server
            const response = await fetch(`${weburl}/getuserbyid/${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "authToken"
                    )}`,
                    "X-CSRF-TOKEN": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
            });
            // Check if the response is okay
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Something went wrong");
            }

            const data = await response.json();
            console.log("User data:", data);
            setUserdata(data);
            setCheckoutData((prevData) => ({
                ...prevData,
                name: data.user?.name || "",
                phone: data.user?.phone_number || "",
            }));

            const lastAddress = data.addresses[data.addresses.length - 1];

            if (lastAddress) {
                setCheckoutData((prevData) => ({
                    ...prevData,
                    name: lastAddress.name || "",
                    phone: lastAddress.phone || "",
                    flatHouseNo: lastAddress.house_no || "",
                    addressType: lastAddress.type || "",
                    landmark: lastAddress.landmark || "",
                    selectedAddress: lastAddress.id,
                }));
            }
            // Handle the user data as needed
        } catch (error) {
            console.error("Error saving order:", error);
        }
    };
    useEffect(() => {
        if (userId) {
            getuserdata(userId);
        }
    }, [userId]);

    const ChangeLocation = (data) => {
        if (data) {
            console.log(data);
            setCheckoutData((prevData) => ({
                ...prevData,
                name: data.name || "",
                phone: data.phone || "",
                flatHouseNo: data.house_no || "",
                addressType: data.type || "",
                landmark: data.landmark || "",
                selectedAddress: data.id,
            }));
        } else {
            setCheckoutData((prevData) => ({
                ...prevData,
                name: "",
                phone: Userdata.user?.phone_number,
                flatHouseNo: "",
                addressType: "",
                landmark: "",
                selectedAddress: "new",
            }));
        }
    };

    // Function to save data in localStorage whenever checkoutData is updated
    {
        /*useEffect(() => {
        if (checkoutData.phone) {
            // Save checkoutData and phone to localStorage
            localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
            localStorage.setItem("phoneNumber", checkoutData.phone);
        }
    }, [checkoutData]); // This effect runs every time checkoutData is updated
    useEffect(() => {
        // Retrieve saved data from localStorage
        const savedPhoneNumber = localStorage.getItem("phoneNumber");
        const savedCheckoutData = localStorage.getItem("checkoutData");
    
        // If both phone number and checkoutData are available
        if (savedPhoneNumber && savedCheckoutData) {
            const parsedCheckoutData = JSON.parse(savedCheckoutData);
    
            // Set the state with the saved data if phone matches
            if (parsedCheckoutData.phone === savedPhoneNumber) {
                setCheckoutData(parsedCheckoutData); 
            }
        }
    }, []); // Run this effect on component mount
    */
    }
    // useEffect(() => {
    //     const savedPhoneNumber = localStorage.getItem("phoneNumber");
    //     const savedCheckoutData = localStorage.getItem("checkoutData");

    //     if (savedPhoneNumber && savedCheckoutData) {
    //         const parsedCheckoutData = JSON.parse(savedCheckoutData);

    //         // Ensure the phone number matches the one in checkoutData
    //         if (parsedCheckoutData.phone === savedPhoneNumber) {
    //             setCheckoutData(parsedCheckoutData);
    //         }
    //     }
    // }, []); // Run only once on component mount

    // useEffect(() => {
    //     const savedCheckoutData = localStorage.getItem("checkoutData");
    //     if (savedCheckoutData) {
    //         setCheckoutData(JSON.parse(savedCheckoutData)); // Set state from localStorage
    //     }
    // }, []);

    const totalAmount = cartItems.reduce(
        (acc, item) =>
            acc + (item.attribute.discount_cost || 0) * item.quantity,
        0
    );
    const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

    const showPhoneOtpModal = () => {
        setIsPhoneOtpModalVisible(true);
    };

    const hidePhoneOtpModal = () => {
        setIsPhoneOtpModalVisible(false);
        setIsCheckoutVisible(true); // Show checkout form after OTP verification
    };

    const handleCheckout = () => {
        const tokenSto = localStorage.getItem("authToken"); // Check if token exists
        if (tokenSto) {
            setIsCheckoutVisible(true);
        } else {
            showPhoneOtpModal();
        }
    };

    {
        /*const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCheckoutData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };*/
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCheckoutData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    //const cartItemsArray = Array.isArray(cartItems) ? cartItems : Object.values(cartItems);
    //const cartItemsArray = Object.keys(cartItems).map((key) => cartItems[key]);
    const cartItemsArray = Array.isArray(cartItems)
        ? cartItems
        : Object.values(cartItems);

    const handleSaveAddress = async (e) => {
        e.preventDefault();
        const userId = localStorage.getItem("userId");
        // Prepare the data to be sent to the server
        // {name: data.shipping_name || "",
        //     phone: data.phone || "",
        //     flatHouseNo: data.house_no || "",
        //     addressType: data.type || "",
        //     landmark: data.landmark || "",
        //     selectedAddress: data.id,}
        if (
            checkoutData.name.length === 0 &&
            checkoutData.house_no.length === 0 &&
            checkoutData.phone.length === 0 &&
            checkoutData.landmark.length === 0 &&
            checkoutData.type.length === 0
        ) {
            alert("Please fill in all the required fields");
            return null;
        }
        const orderData = {
            ...checkoutData,
            cartItems: JSON.stringify(cartItemsArray), // Convert to JSON string
            //cartItems: cartItemsArray,
            totalAmount: totalAmount,
            user_id: userId, // Ensure user_id is included here
        };

        console.log("Order Data:", orderData); // Debugging line

        try {
            // Send the data to the server
            const response = await fetch(`${weburl}/api/orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "authToken"
                    )}`,
                    "X-CSRF-TOKEN": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
                body: JSON.stringify(orderData), // Convert orderData to JSON string
            });
            const data = await response.json();

            console.log(data);
            if (response.ok) {
                console.log("Order placed successfully:", data);
                toastr.success("Order placed successfully!");

                localStorage.removeItem("cartItems");

                // Optionally, clear the cart state in your application
                // onUpdateCart([]);
                handleUpdateCart([]);
                setIsCheckoutVisible(false);
                // Redirect to the orders page without refreshing the browser
                //  navigate("/admart-new/account/orders");

                navigate(`${routeurl}account/orders`); // Change the path to the actual orders route
                // Trigger the checkout process or redirect the user
                //onProceedToCheckout();
            } else {
                // Handle server errors
                const errorData = await response.json();
                console.error(
                    "Order creation failed:",
                    error.response.data.error || error.response.data.message
                );

                //console.error('Order creation failed:', errorData.message);
                // Show error message with toastr
                // toastr.error(
                //     "Order creation failed: " +
                //         (errorData.message || "Unknown error")
                // );
            }
        } catch (error) {
            console.error("Error saving order:", error);
        }
    };

    const tokenCheckout = localStorage.getItem("authToken"); // Check if token exists

    const toggleCart = () => {
        const cart = document.getElementById("mycart");
        if (cart) {
            cart.classList.toggle("show");
        }
    };

    return (
        <div className="cart-slide" id="mycart">
            <div className="d-flex flex-column" style={{ height: "100vh" }}>
                <div className="d-flex align-items-center p-4">
                    <button
                        className="close-button bg-white"
                        aria-label="Close"
                        onClick={toggleCart}
                    >
                        <span aria-hidden="true" className="h2">
                            &times;
                        </span>
                    </button>

                    <h4>Shopping Cart</h4>
                </div>
                <div
                    className={`bg-light p-3 cart-sidebar h-100`}
                    style={{ overflow: "auto" }}
                >
                    {cartItems.length > 0 && (
                        <ul>
                            {cartItems.map((item) => {
                                const discountCost =
                                    Number(item.attribute.discount_cost) || 0;
                                const originalCost =
                                    Number(item.attribute.cost) || 0;

                                return (
                                    <li
                                        key={`${item.product.id}-${item.attribute.id}-${item.shopId}`}
                                        className="cart-item bg-white p-3 rounded border"
                                    >
                                        <img
                                            src={`${weburl}/${item.product.image}`}
                                            alt={item.product.name}
                                            className="cart-item-image"
                                        />
                                        <div className="cart-item-details">
                                            <p>{item.product.name}</p>
                                            <p>
                                                Attribute: {item.attribute.name}
                                            </p>
                                            <p>
                                                <span className="discounted-price">
                                                    ₹{discountCost.toFixed(2)}
                                                </span>
                                                <span className="original-price">
                                                    ₹{originalCost.toFixed(2)}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="d-block">
                                            <div className="custom-quantity-controls bg-primary rounded mb-2">
                                                <button
                                                    className="bg-transparent"
                                                    onClick={() =>
                                                        handleQuantityChange(
                                                            item.product,
                                                            item.shopId,
                                                            item.attribute,
                                                            item.quantity - 1
                                                        )
                                                    }
                                                    aria-label="Decrease quantity"
                                                    disabled={
                                                        item.quantity <= 1
                                                    }
                                                >
                                                    -
                                                </button>
                                                <span className="text-white px-2">
                                                    {item.quantity}
                                                </span>
                                                <button
                                                    className="bg-transparent"
                                                    onClick={() =>
                                                        handleQuantityChange(
                                                            item.product,
                                                            item.shopId,
                                                            item.attribute,
                                                            item.quantity + 1
                                                        )
                                                    }
                                                    aria-label="Increase quantity"
                                                    disabled={
                                                        item.quantity >=
                                                        (item.attribute
                                                            .stock_quantity ||
                                                            Infinity)
                                                    }
                                                >
                                                    +
                                                </button>
                                                {errorMessage && (
                                                    <p className="error-message">
                                                        {errorMessage}
                                                    </p>
                                                )}
                                            </div>

                                            <button
                                                onClick={() =>
                                                    handleDeleteItem(
                                                        item.product,
                                                        item.shopId,
                                                        item.attribute
                                                    )
                                                }
                                                className="delete-button w-100 rounded"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {errorMessage && (
                        <div className="error-message">{errorMessage}</div>
                    )}
                    <div className="d-block "></div>

                    <div
                        className={`cart-summary ${
                            cartItems.length === 0 && "border-0"
                        }`}
                    >
                        <h5>Total Items: {totalItems}</h5>
                        <h5>Total Amount: ₹{totalAmount.toFixed(2)}</h5>
                    </div>

                    {isPhoneOtpModalVisible && !tokenCheckout && (
                        <PhoneOtpModal
                            isOpen={isPhoneOtpModalVisible}
                            onClose={hidePhoneOtpModal}
                            onVerify={(phoneNumber) => {
                                hidePhoneOtpModal();
                            }}
                        />
                    )}
                    {isPhoneOtpModalVisible && (
                        <PhoneOtpModal onClose={hidePhoneOtpModal} />
                    )}

                    {cartItems.length > 0 && tokenCheckout && (
                        <>
                            <hr />
                            <h4>Save Address</h4>

                            {Userdata.length !== 0 &&
                                Userdata.addresses.length !== 0 &&
                                Userdata.addresses.map((address, index) => (
                                    <div
                                        className="form-check"
                                        key={address.id}
                                    >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="selectedAddress"
                                            defaultChecked={
                                                index ===
                                                Userdata.addresses.length - 1
                                            } // Check if it's the last input
                                            id={`address-${address.id}`} // unique ID for each address
                                            value={address.user_id || ""}
                                            onChange={() => {
                                                handleChange; // Call handleChange
                                                ChangeLocation(address); // Call ChangeLocation
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`address-${address.id}`}
                                        >
                                            <b className="pb-0">
                                                {address.shipping_name},{" "}
                                                {address.pincode}{" "}
                                                {/* Adjust according to your address object */}
                                                <span className="badge bg-primary ms-2">
                                                    {address.type}
                                                </span>
                                            </b>
                                            <p
                                                className="pt-0"
                                                style={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    display: "inline-block",
                                                    width: 250,
                                                    maxWidth: "100%",
                                                }}
                                            >
                                                {address.house_no}{" "}
                                                {/* Adjust according to your address object */}
                                            </p>
                                        </label>
                                    </div>
                                ))}

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="selectedAddress"
                                    id="flexRadioDefault22"
                                    defaultChecked=""
                                    value={"new"}
                                    onChange={() => {
                                        handleChange; // Call handleChange
                                        ChangeLocation(); // Call ChangeLocation
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault22"
                                >
                                    <b className="pb-0">
                                        Add Address{" "}
                                        <i className="ri-map-pin-2-fill"></i>
                                    </b>
                                    <p
                                        className="pt-0"
                                        style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            display: "inline-block",
                                            width: 250,
                                            maxWidth: "100%",
                                        }}
                                    >
                                        {" "}
                                        <i class="ri-add-circle-line"></i> Add
                                        more address
                                    </p>
                                </label>
                            </div>

                            <hr />
                        </>
                    )}

                    {tokenCheckout && cartItems.length > 0 && (
                        <div className="checkout-form">
                            <h4>Checkout</h4>
                            <form onSubmit={handleSaveAddress}>
                                <div>
                                    <label className="w-100 mb-2">
                                        Save Address As
                                        <select
                                            style={{ display: "block" }}
                                            name="addressType"
                                            className="form-control myselect w-100"
                                            value={checkoutData.addressType}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="Home">Home</option>
                                            <option value="Work">Work</option>
                                            <option value="Hotel">Hotel</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Flat/House No/Building Name
                                        <textarea
                                            style={{ height: 80 }}
                                            className="form-control w-100"
                                            name="flatHouseNo"
                                            value={
                                                checkoutData.flatHouseNo || ""
                                            }
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>

                                <div>
                                    <label className="mb-2 w-100">
                                        Nearby Landmark
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            name="landmark"
                                            value={checkoutData.landmark || ""}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>

                                <div>
                                    <label className="mb-2 w-100">
                                        Your Name
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control w-100"
                                            value={checkoutData.name || ""}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Phone Number
                                        <input
                                            type="text"
                                            name="phone"
                                            className="form-control w-100"
                                            value={checkoutData.phone || ""}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="payLater"
                                            checked={checkoutData.payLater}
                                            onChange={handleChange}
                                        />
                                        Pay Later
                                    </label>
                                </div>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary py-2 px-3 me-auto w-100 mt-2 "
                                    >
                                        SAVE AND CHECKOUT
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
                <div className="d-block p-2">
                    {cartItems.length === 0 ? (
                        <Link
                            to={weburl} // Correct interpolation of variable
                            className="btn btn-dark bg-dark py-2 px-3 w-100"
                            disabled={cartItems.length === 0}
                            onClick={handleCheckout}
                        >
                            <i className="ri-home-3-fill"></i> Go Home
                        </Link>
                    ) : (
                        <button
                            className="checkout-button d-flex justify-content-between gap-2 align-items-center"
                            disabled={cartItems.length === 0}
                            onClick={handleCheckout}
                        >
                            <div className="d-block">
                                <p
                                    className="p m-0 fw-bold"
                                    style={{ lineHeight: "1.2em" }}
                                >
                                    ₹{totalAmount.toFixed(2)}
                                </p>
                                <p
                                    className="p m-0"
                                    style={{ lineHeight: "1.2em" }}
                                >
                                    TOTAL{" "}
                                </p>
                            </div>
                            <span className="h5 ">
                                {" "}
                                Proceed to Checkout{" "}
                                <i className="ri-arrow-right-s-line"></i>
                            </span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

CartSidebar.propTypes = {
    cartItems: PropTypes.array.isRequired,
    onUpdateCart: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProceedToCheckout: PropTypes.func.isRequired,
};

export default CartSidebar;
