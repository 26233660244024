import React from "react";

const TopSectionSkeleton = () => {
    return (
        <div className="container-fluid mb-3">
            <div className="smallbannerimg">
                <div
                    className="skeleton "
                    style={{
                        height: "200px",
                        borderRadius: "20px",
                    }}
                />
                <div
                    className="skeleton "
                    style={{
                        height: "200px",
                        borderRadius: "20px",
                    }}
                />

                <div
                    className="skeleton "
                    style={{
                        height: "200px",
                        borderRadius: "20px",
                    }}
                />

                <div
                    className="skeleton "
                    style={{
                        height: "200px",
                        borderRadius: "20px",
                    }}
                />
            </div>
        </div>
    );
};

export default TopSectionSkeleton;
