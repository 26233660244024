import React from "react";
import "./OrderDetailsPopup.css"; // Create this CSS file for styles

const OrderDetailsPopup = ({ order, onClose }) => {
  return (
    <div className="order-details-popup">
      <div className="popup-content">
        {/* Close button */}
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>

        {/* Order ID Heading */}
        <h2>Order Details - Order ID: {order.id}</h2>

        {/* Table for displaying items */}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {order.items.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.product.name}</td>
                <td>{item.quantity}</td>
                <td>₹{item.price}</td>
                <td>₹{item.price * item.quantity}</td> {/* Total for this item */}
              </tr>
            ))}
          </tbody>
        </table>

        {/* Summary */}
        <div className="order-summary">
          <p>
            <strong>Order Total: </strong>₹{order.total}
          </p>
          <p>
  <strong>Date: </strong>{new Date(order.created_at).toLocaleDateString('en-GB')}
</p>

        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPopup;
