import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import CartSidebar from "./CartSidebar";

import UserAccountMenu from "./UserAccountMenu"; // Import UserAccountMenu component
import ProductItemCat from "./ProductItemCat";
import { weburl } from "../helpers/ApiUrls";
import { useThemeContext } from "../helpers/ThemeContext";
import ShopSkeleton from "./skeleton/ShopSkeleton";
import ShopPageSkeleton from "./skeleton/ShopPageSkeleton";
import LoaderSkeleton from "./skeleton/LoaderSkeleton";

const ShopProducts = ({ latitude, longitude }) => {
    const {
        cartItems,
        handleUpdateCart,
        handleQuantityChange,
        handleAddToCart,
    } = useThemeContext();

    const { categorySlug } = useParams();
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingPro, setLoadingPro] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSize, setSelectedSize] = useState("250 grams");
    const [popupProduct, setPopupProduct] = useState(null);
    //const [cartItems, setCartItems] = useState([]);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [shopId, setShopId] = useState(null);
    const [isUserAccountMenuVisible, setIsUserAccountMenuVisible] =
        useState(false); // Define the state here
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        prevPageUrl: null,
        nextPageUrl: null,
    });

    useEffect(() => {
        const checkAuthStatus = () => {
            const token = localStorage.getItem("authToken"); // Or any key you use for storing the token
            if (token) {
                // You may also perform token validation here
                setIsUserAccountMenuVisible(true);
            } else {
                setIsUserAccountMenuVisible(false);
            }
        };

        checkAuthStatus();
    }, []);

    const fetchProducts = async () => {
        setLoading(true);
        setLoadingPro(true);
        try {
            const latitude =
                document.getElementById("rec_latitute")?.value || latitude;
            const longitude =
                document.getElementById("rec_longitute")?.value || longitude;

            const response = await axios.get(
                `${weburl}/api/near/fetch-products`,
                {
                    params: {
                        latitude: latitude,
                        longitude: longitude,
                        category_slug: categorySlug,
                        subcategory_slug: selectedCategory, // Fixed to use selectedCategory
                        page: pagination.currentPage,
                    },
                }
            );

            setProducts(response.data.products || []);
            setCategories(response.data.categories || []);
            setShopId(response.data.shop); // Set the shopId from the response
            setPagination({
                currentPage: response.data.current_page || 1,
                lastPage: response.data.last_page || 1,
                prevPageUrl: response.data.prev_page_url || null,
                nextPageUrl: response.data.next_page_url || null,
            });
        } catch (error) {
            //setError('Failed to fetch products');
        } finally {
            setLoading(false);
            SetLoadingPro(true);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [
        categorySlug,
        selectedCategory,
        pagination.currentPage,
        latitude,
        longitude,
    ]);

    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId); // Update selectedCategory and fetch products
    };

    const handlePopupOpen = (product) => {
        setPopupProduct(product);
        document.getElementById("category-popup").style.display = "flex";
    };

    const handlePopupClose = () => {
        setPopupProduct(null);
        document.getElementById("category-popup").style.display = "none";
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= pagination.lastPage) {
            setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
        }
    };

    const openPhoneOtpModal = () => setIsPhoneOtpModalVisible(true);
    const closePhoneOtpModal = () => setIsPhoneOtpModalVisible(false);

    const openCartSidebar = () => setIsCartOpen(true);
    const closeCartSidebar = () => setIsCartOpen(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="shop-products">
            <div id="category-popup" style={{ display: "none" }}>
                <div className="popup-box">
                    <div className="popupheading">
                        <h3>{popupProduct?.name}</h3>
                        <i
                            id="closepopup"
                            className="fa fa-close"
                            onClick={handlePopupClose}
                        ></i>
                    </div>
                    {popupProduct && (
                        <div className="popup-product">
                            <div className="product-img">
                                <img
                                    style={{ width: "60px" }}
                                    src={`${weburl}/${popupProduct.image}`}
                                    alt={popupProduct.name}
                                />
                                <span>{popupProduct.size || "200 grams"}</span>
                            </div>

                            {/*<select class="form-select mb-2">
                                <option value="2">sasas (1 in stock)</option>
                                <option value="3">ccccc (3332 in stock)</option>
                            </select>*/}

                            <div className="price-box">
                                <span className="price-regular">
                                    ₹
                                    {popupProduct.discountedPrice ||
                                        popupProduct.price}
                                </span>
                                {popupProduct.discountedPrice && (
                                    <span className="price-old">
                                        <del>₹{popupProduct.price}</del>
                                    </span>
                                )}
                            </div>
                            <div className="box-cart">
                                <a href={`#`} className="btn btn-cart">
                                    <i className="fa fa-shopping-cart"></i> Add
                                    to cart
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {loading ? (
                <ShopPageSkeleton />
            ) : (
                <main>
                    <div className="shop-main-wrapper section-space">
                        <div className="container-fluid">
                            <div className="row">
                                <div
                                    className={`col-lg-3 col-3 ${
                                        !loading &&
                                        categories &&
                                        categories.length > 0
                                            ? "d-block"
                                            : "d-none"
                                    }`}
                                >
                                    <div id="sidebarsec">
                                        {loadingPro && categories.length > 0 ? (
                                            categories.map((category) => (
                                                <button
                                                    type="button"
                                                    key={category.id}
                                                    className="sidebarbox active w-100"
                                                    onClick={() =>
                                                        handleCategoryClick(
                                                            category.id
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg"
                                                        style={{
                                                            width: "80px",
                                                            aspectRatio: "1/1",
                                                        }}
                                                        className="rounded"
                                                    />
                                                    <h3>{category.name}</h3>
                                                </button>
                                            ))
                                        ) : (
                                            <p className="p-4">
                                                No Sub categories found
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`${
                                        categories && categories.length > 0
                                            ? "col-lg-9 col-9"
                                            : "col-12"
                                    }  order-1 order-lg-2 `}
                                >
                                    <div className="shop-product-wrapper">
                                        <div className="shop-top-bar">
                                            <div className="row align-items-center">
                                                <div className="col-lg-7 col-md-6 order-2 order-md-1">
                                                    <h1>Shop Products</h1>
                                                </div>
                                                <div className="col-lg-5 col-md-6 order-1 order-md-2">
                                                    <div className="top-bar-right">
                                                        <div className="product-short">
                                                            <p>Sort By : </p>
                                                            <select
                                                                className="nice-select"
                                                                name="sortby"
                                                                id="sortby"
                                                            >
                                                                <option value="relevance">
                                                                    Relevance
                                                                </option>
                                                                <option value="name-asc">
                                                                    Name (A - Z)
                                                                </option>
                                                                <option value="name-desc">
                                                                    Name (Z - A)
                                                                </option>
                                                                <option value="price-asc">
                                                                    Price (Low
                                                                    &gt; High)
                                                                </option>
                                                                <option value="rating">
                                                                    Rating
                                                                    (Lowest)
                                                                </option>
                                                                <option value="model-asc">
                                                                    Model (A -
                                                                    Z)
                                                                </option>
                                                                <option value="model-desc">
                                                                    Model (Z -
                                                                    A)
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="shop-product-wrap grid-view row mbn-30">
                                            {error && <p>{error}</p>}

                                            {products.length === 0 && (
                                                <div
                                                    className="alert alert-danger  text-center align-items-center"
                                                    role="alert"
                                                >
                                                    <img
                                                        src={`${weburl}/assets/images/no-order.png`}
                                                        className="d-block mx-auto"
                                                        style={{
                                                            maxWidth: 70,
                                                            marginRight: 10,
                                                        }}
                                                    />

                                                    <h3>
                                                        We don't have delivery
                                                        at this location
                                                    </h3>
                                                </div>
                                            )}

                                            {products.length !== 0 &&
                                                products.map((product) => (
                                                    <div
                                                        key={product.id}
                                                        className="col-md-3 col-sm-6"
                                                    >
                                                        <ProductItemCat
                                                            product={product}
                                                            shopId={shopId}
                                                            cartItems={
                                                                cartItems
                                                            }
                                                            updateSideCart={
                                                                handleUpdateCart
                                                            } // Pass the function here
                                                            onPopupOpen={() =>
                                                                handlePopupOpen(
                                                                    product
                                                                )
                                                            } // Open popup on click
                                                        />
                                                    </div>
                                                ))}
                                        </div>

                                        {/* <div className="pagination">
                                        <button onClick={() => handlePageChange(pagination.currentPage - 1)} disabled={!pagination.prevPageUrl}>
                                            Previous
                                        </button>
                                        <span>Page {pagination.currentPage} of {pagination.lastPage}</span>
                                        <button onClick={() => handlePageChange(pagination.currentPage + 1)} disabled={!pagination.nextPageUrl}>
                                            Next
                                        </button>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
            <CartSidebar
                cartItems={cartItems}
                onUpdateCart={handleUpdateCart}
                isOpen={isCartOpen}
                onClose={() => setIsCartOpen(false)}
                onProceedToCheckout={openPhoneOtpModal} // Pass down function to CartSidebar
            />
            {/*<CartSidebar
                isOpen={isCartOpen}
                onClose={closeCartSidebar}
                cartItems={cartItems}
                updateCart={handleUpdateCart}
            />*/}
            {isUserAccountMenuVisible && <UserAccountMenu />}
        </div>
    );
};

export default ShopProducts;
