// TopSection.jsx
import React from "react";
import { Link } from "react-router-dom";
import { weburl } from "../helpers/ApiUrls";
//import './TopSection.css'; // Ensure to add styling if needed

const TopSection = ({ homeCategories }) => {
    return (
        <section className="banner-statistics section-space">
            <div className="container-fluid">
                <div className="smallbannerimg">
                {homeCategories.map((category, index) => (
                        <div key={index}>
                            <Link
                                to={category.cat_link} // Use <Link> for client-side navigation
                                className="banner-item"
                            >
                                <img
                        src={category.image}
                        alt="Pet Care 1"
                    />
                            </Link>
                        </div>
                    ))}
                    
                </div>
            </div>
        </section>
    );
};

export default TopSection;
