import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FindNearbyShops from "./components/FindNearbyShops";
import ShopProducts from "./components/ShopProducts";
import ProductDetails from "./components/ProductDetails";
import OrdersPage from "./components/OrdersPage";
import CartSidebar from "./components/CartSidebar";
import { weburl, routeurl } from "./helpers/ApiUrls";
import { ThemeProvider } from "./helpers/ThemeContext";
import { Toaster } from "react-hot-toast";

const ScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
};

const App = () => {
    const [selectedShop, setSelectedShop] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const handleUpdateCart = (updatedCartItems) => {
        setCartItems(updatedCartItems);
        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    };
    useEffect(() => {
        console.log("weburl", weburl);
        const handleLocationFetched = (event) => {
            const { latitude, longitude } = event.detail;
            setLatitude(latitude);
            setLongitude(longitude);
            console.log("Latitude:", latitude, "Longitude:", longitude);
        };

        window.addEventListener("locationFetched", handleLocationFetched);

        return () => {
            window.removeEventListener(
                "locationFetched",
                handleLocationFetched
            );
        };
    }, []);

    return (
        <BrowserRouter>
            <ThemeProvider>
                <Toaster />
                <Routes>
                    <Route
                        path={`${routeurl}`}
                        element={
                            <FindNearbyShops onSelectShop={setSelectedShop} />
                        }
                    />
                    <Route
                        path={`${routeurl}account/orders`}
                        element={<OrdersPage />}
                    />
                    <Route
                        path={`${routeurl}shop/:categorySlug`}
                        element={
                            <ShopProducts
                                latitude={latitude}
                                longitude={longitude}
                            />
                        }
                    />
                    <Route
                        path={`${routeurl}product/:slug`}
                        element={
                            <ProductDetails
                                latitude={latitude}
                                longitude={longitude}
                                updateSideCart={handleUpdateCart} // Pass updateSideCart here
                            />
                        }
                    />
                </Routes>
                <ScrollToTop />
            </ThemeProvider>
        </BrowserRouter>
    );
};

// Initialize the app
const root = ReactDOM.createRoot(document.getElementById("app_cart"));
root.render(<App />);
