import React, { useState, useEffect } from "react";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import CartSidebar from "./CartSidebar";
import UserAccountMenu from "./UserAccountMenu"; // Import UserAccountMenu component
import { weburl } from "../helpers/ApiUrls";
import Pagination from "react-js-pagination"; // Install react-js-pagination for pagination
import OrderDetailsPopup from "./OrderDetailsPopup"; // Import the popup component
import LoaderSkeleton from "./skeleton/LoaderSkeleton";
import { useThemeContext } from "../helpers/ThemeContext";
const OrdersPage = () => {
    const { cartItems, handleUpdateCart } = useThemeContext();
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null); // State for selected order
    const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // To handle pagination
    const [totalOrders, setTotalOrders] = useState(0); // Total orders count
    const ordersPerPage = 5; // Number of orders per page
    const userId = localStorage.getItem("userId"); // Assuming user ID is stored in local storage
    const [isUserAccountMenuVisible, setIsUserAccountMenuVisible] =
        useState(false); // Define the state here
    const [detailsLoading, setDetailsLoading] = useState(false); // State to handle loading of order details

    const handleOrderDetails = async (orderId) => {
        setDetailsLoading(true);
        try {
            const response = await axios.get(
                `${weburl}/api/orders/${orderId}/details`
            );
            setSelectedOrder(response.data); // Set the fetched order details to selectedOrder
            setShowPopup(true); // Show the popup after details are fetched
        } catch (err) {
            setError("Failed to fetch order details");
        } finally {
            setDetailsLoading(false);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
        setSelectedOrder(null);
    };

    useEffect(() => {
        const checkAuthStatus = () => {
            const token = localStorage.getItem("authToken"); // Or any key you use for storing the token
            if (token) {
                setIsUserAccountMenuVisible(true);
            } else {
                setIsUserAccountMenuVisible(false);
            }
        };

        checkAuthStatus();
    }, []);

    // Fetch orders with pagination
    useEffect(() => {
        const fetchOrders = async (pageNumber = 1) => {
            setLoading(true);
            try {
                const response = await axios.get(`${weburl}/api/orders`, {
                    params: {
                        user_id: userId,
                        page: pageNumber,
                        per_page: ordersPerPage,
                    },
                });
                setOrders(response.data.orders);
                setTotalOrders(response.data.total); // Assuming API response includes total order count
            } catch (err) {
                setError("Failed to fetch orders");
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchOrders(currentPage);
        } else {
            setError("User not authenticated");
            setLoading(false);
        }
    }, [userId, currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (error) return <div>{error}</div>;

    return (
        <div
            className="orders-page container p-4 "
            style={{ minHeight: "70vh" }}
        >
            {!loading ? (
                <>
                    <h1>Your Orders</h1>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Order ID</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th>Details</th>{" "}
                                    {/* New column for details button */}
                                </tr>
                            </thead>
                            <tbody>
                                {orders.length > 0 ? (
                                    orders.map((order) => (
                                        <tr key={order.id}>
                                            <td>{order.id}</td>
                                            <td>{order.quantity}</td>
                                            <td>₹{order.total}</td>
                                            <td>
                                                <button
                                                    className="btn btn-info"
                                                    onClick={() =>
                                                        handleOrderDetails(
                                                            order.id
                                                        )
                                                    }
                                                >
                                                    Show Details
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No orders found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <LoaderSkeleton />
            )}

            {/* Show Order Details Popup */}
            {showPopup && selectedOrder && (
                <OrderDetailsPopup
                    order={selectedOrder}
                    onClose={closePopup}
                    loading={detailsLoading}
                />
            )}

            {/* Pagination Component */}
            <div className="d-flex justify-content-center">
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={ordersPerPage}
                    totalItemsCount={totalOrders}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                />
            </div>
            <div className="d-none">
                <CartSidebar
                    className="d-none"
                    style={{ display: "none" }}
                    cartItems={cartItems}
                    onUpdateCart={handleUpdateCart}
                />
            </div>

            {isUserAccountMenuVisible && <UserAccountMenu />}
        </div>
    );
};

export default OrdersPage;
